<template>
	<div id="code">
		<div class="fom">
			<div class="div">
				<div class="top">
					<van-icon name="description" size="20px" />
					<span>基本信息</span>
				</div>
				<van-cell-group>
					<van-field readonly label="资产状态:" :value="cideData.state==1?'使用中':cideData.state==''? '':'闲置'" />
					<van-field readonly label="资产编码:" :value="cideData.code" />
					<van-field readonly label="资产名称:" :value="cideData.name" />
					<van-field readonly label="资产分类:" :value="cideData.classification" />
					<van-field readonly label="品牌:" v-if="cideData.classification !='图书'" :value="cideData.brand" />
					<van-field readonly label="型号:" v-if="cideData.classification !='图书'" :value="cideData.model" />
					<van-field readonly label="科目:" v-if="cideData.classification =='图书'" :value="cideData.subject" />
					<van-field readonly label="管理员:" :value="cideData.administrators" />
					<van-field readonly label="所在位置:" :value="cideData.position" />
				</van-cell-group>
			</div> 
		</div>
		<van-loading size="24px" v-if="Loadings" vertical>加载中...</van-loading>
	</div>
</template>

<script>
	import {
		Notify,
		Cell,
		CellGroup,
		Field,  
		Icon,
		Loading,
	} from 'vant';
	import https from '../https.js'
	export default {
		components: {
			[Notify.name]: Notify,
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup,
			[Field.name]: Field,
			[Icon.name]: Icon,
			[Loading.name]: Loading,
			
		},
		data() {
			return {
				html: "https://jinshuju.net/f/zMw9Ew",
				Loadings:false,
				cideData: { //手机和电脑
					state: "",
					code: "",
					name: "",
					classification: "",
					brand: "",
					model: "",
					subject: "",
					administrators: "",
					position: "",
				},
			}
		},
		created() {
			let id = this.$route.params.id
			console.log(id)
			var that = this;
			that.Loadings = true;
			https.fetchGet('/api/property/findByCode', {
				code: id,
			}).then(res => {
				that.Loadings = false
				if (res.data && res.data.data) {
					that.cideData = res.data.data[0]
				}


			}).catch(err => {
				that.Loadings = false
				Notify({
					type: 'warning',
					message: '当前人数过多，请稍后重试'
				});
				console.log(err)
			})

		}
	}
</script>

<style lang="less">
	van-cell-group {
		background-color: red;
	}

	body {
		background-color: #F2F5FA;
	}

	#code {
		max-width: 414px;
		margin: 0 auto;

		.fom {

			.div {
				padding: 10px 0;
				margin: 30px 10px;
				overflow: hidden;
				border-radius: 12px;
				box-shadow: 0px 3px 3px -4px #000, 0px 0px 2px 0px #ccc;
				background-color: #fff;
			}
		}

		.van-cell {
			padding: 4px 16px;
		}

		.van-cell__value {
			font-size: 14px;
			color: #323233;
		}
	}

	.top {
		// height: 30px;
		box-sizing: border-box;
		padding: 10px 16px;
		display: flex;
		align-items: center;

		span {
			margin-left: 10px;
			font-size: 16px;
		}

		i::before {
			background-color: #35C7CC;
			border-radius: 8px;
			padding: 4px;
			color: #fff;
		}
	}
</style>
